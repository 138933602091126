import React, { useCallback, useEffect, useRef, useState } from "react";

import { Button, Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import { useSelector, useDispatch } from 'react-redux';
import { fetchTrail } from '../store/trailSlice'

import TicketForm from '../components/AddTicketForm/TicketForm';
import ContacsForm from "../components/AddTicketForm/ContacsForm";

import { useParams } from "react-router-dom";
import 'air-datepicker/air-datepicker.css';

const buttonBox = {
  buttonBox: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    margin: '25px 0px 25px 0px'
  },
  p: {
    margin: '0px 0px 0px 10px'
  },
  selectBox: {
    display: 'flex',
    minWidth: '640px',
  },
  select: {
    marginRight: '20px',
    width: '100%',
  },
  editBox: {
    border: '1px solid #e4e4e4',
    boxSizing: 'border-box',
    borderRadius: '5px',
    marginTop: '20px'
  }
}

function Trail() {
  const dispatch = useDispatch()

  // Запрашиваю всё маршруты 
  useEffect(() => {
    dispatch(fetchTrail())
  }, [dispatch])

  const schedules = useSelector(state => state.schedules.schedules)
  //-------------------------
  const { id, date } = useParams()

  const [selectedSchedule, setSelectedSchedule] = useState([])

  useEffect(() => {
    const schedule = schedules.find(idx => idx.id === id)
    setSelectedSchedule(schedule)
  }, [id, schedules])

  // добавляем пустые билеты
  const [ticketForm, setTicketForm] = useState([{ id: 0 }])

  const addTicket = () => {
    setTicketForm([...ticketForm, {
      id: +ticketForm[ticketForm.length - 1].id + 1
    }])
  }
  //----------------

  const [ticketsSum, setTicketsSum] = useState(0)

  const removeTicket = (id) => {
    console.log(ticketForm.length);
    if (ticketForm.length < 2) {
      alert('в броне должен быть хоть один билет')
      return
    }
    const removedTicket = ticketForm.filter(t => t.id !== id)
    setTicketForm(removedTicket)


  }

  const getInputPrice = useRef('')
  // Рескция на изменения checkbox детский
  const [child, setChild] = useState('')
  const childrenWatch = useCallback((params) => {
    setChild(params);
  }, [])
  // Суммируем цену билетов
  useEffect(() => {
    const sum = [...getInputPrice.current.querySelectorAll("[name='price']")].reduce((akum, el) => akum + +el.value.replace(/[A-z]|[А-я]|[:]/g, ''), 0)
    setTicketsSum(sum);
  }, [ticketForm, child, getInputPrice])


  return (
    <section className="pages">
      <Container ref={getInputPrice}>
        <h1>ДОБАВИТЬ БРОНЬ</h1>
        <Row>

          <Col lg="6" className='mb-3'>
            <div style={buttonBox.buttonBox} className="button-box">
              <Button onClick={addTicket}> + </Button>
              <p style={buttonBox.p}>Количество билетов: {ticketForm.length}</p>
            </div>
          </Col>

          {ticketForm.map((form, i) =>
            <Col lg="12" className='mb-3' key={form.id}>
              <TicketForm idx={form.id} selectedSchedule={selectedSchedule} idTicket={form.id} removeTicket={removeTicket} childrenWatch={childrenWatch} />
            </Col>
          )}

        </Row>
      </Container>
      <Container>
        {ticketForm.length > 0 ? <Row className="justify-content-end">
          <Col lg="4" className="justify-content-end d-flex">
            <h2>К оплате: {ticketsSum} РУБ</h2>
          </Col>
        </Row> : ''}
      </Container>
      <Container className="edit-box" style={buttonBox.editBox}>
        {ticketForm.length > 0 ? <ContacsForm selectDate={date} ticketsPriceSum={ticketsSum} selectedSchedule={selectedSchedule} /> : ''}
      </Container>

    </section >
  );
};

export default Trail;