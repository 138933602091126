import React, { useEffect, useRef, useState } from "react";

import AirDatepicker from 'air-datepicker';
import 'air-datepicker/air-datepicker.css';

const styles = {

  label: {
    display: "block",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "19px",
    color: "#7B7B7B",
    marginBottom: "5px",
  },
  input: {
    maxWidth: "270px",
    width: "100%",
    height: "40px",
    padding: "0 8px",
    border: "1px solid #DEDEDE",
    boxSizing: "border-box",
    borderRadius: "3px",
  }
}

export default function Input({ style, calendarOptions, title, name, value = '', cleanValue, className = '', type = "text", placeholder = '', isDisabled = false }) {

  const [val, setVal] = useState('');
  const [readonly, setReadonly] = useState('');

  const inputRef = useRef('')

  useEffect(() => {
    setVal(value)
  }, [value])

  useEffect(() => {
    if (cleanValue) inputRef.current.value = ''
  }, [cleanValue])

  useEffect(() => {
    if (className) setReadonly('readonly')
    if (className && calendarOptions) new AirDatepicker('.' + className, calendarOptions)
  }, [])

  return (
    <div className="inputStyle" >
      <label style={styles.label}>{title}</label>
      <input key={title} style={styles.input} disabled={isDisabled} placeholder={placeholder} ref={inputRef} readOnly={readonly} autoComplete={'none'} className={className} type={type} name={name} defaultValue={val} onChange={(e) => { setVal(e.target.value) }} />
    </div>
  )
}