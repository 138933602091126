import React from 'react'
import { AppRouter } from './components/AppRouter'

function App() {
  return (
      <AppRouter />
  );
}

export default App;
