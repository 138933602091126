import React from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import { privetRoutes } from '../routes'
import Layout from '../pages/Layout'

export function AppRouter() {
  return (
    <Routes>
      <Route element={<Layout />}>
        {privetRoutes.map(route =>
          <Route
            path={route.path}
            element={<route.element />}
            exact={route.exact}
            key={route.path}
          />
        )}
        <Route path='*' element={<Navigate to="/trail" replace />} />
      </Route>
    </Routes>
  );
}
