import React from 'react';
import InputSchedule from './InputSchedule';
import { Row, Col, } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom'
const style = {
  h3: {
    marginTop: '15px'
  },
  button: {
    width: '100%'
  },
  p: {
    margin: '10px 0 0 0',
    fontWeight: '600'
  },
  row: {
    margin: '10px 0px',
    background: '#eee',
    borderRadius: '3px'
  },
  label: {
    display: "block",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "19px",
    color: "#7B7B7B",
    marginBottom: "5px",
  },
}

function Schedule({ params, date }) {

  return (
    <Row style={style.row}>
      <p style={style.p}>Маршрут: {params.wherefrom} - {params.where}</p>
      <InputSchedule title={'Время отправки'} className={"mt-2"} value={params.sendingtime} />
      <InputSchedule title={'Время прибытия'} className={"mt-2"} value={params.arrivaltime} />
      <InputSchedule title={'Цена руб'} className={"mt-2"} value={params.price} />
      <InputSchedule title={'Время в пути'} className={"mt-2"} value={params.traveltime} />

      <InputSchedule title={'Ост. отправки'} className={"mt-2"} value={params.stoppingsending} />
      <InputSchedule title={'Ост. прибытия'} className={"mt-2"} value={params.arrivalstop} />

      <Col lg="6" md="6" className="mt-2 mb-4">
        <div className="inputStyle">
          <label style={style.label}>Выбрать направление</label>
          <Link style={style.button} className="btn btn-danger" variant="danger" to={'/trail/' + params.id + '/' + date}>Выбрать</Link>
        </div>
      </Col>
    </Row>
  )
}
export default Schedule;