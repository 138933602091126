import React, { useCallback, useEffect, useState } from 'react';

import Input from '../components/UI/Input';
import SelectSearch from '../components/UI/SelectSearch/SelectSearch';
import { Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
const style = {
  label: {
    display: "block",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "19px",
    color: "#7B7B7B",
    marginBottom: "5px",
  },
}
const daysR = ["воскресенье", "понедельник", "вторник", "среда",
  "четверг", "пятница", "суббота"]

function SearchSchedule({ trails, schedules, searchCurrentSchedule, trailDelete }) {

  const [whereTrailFrom, setWhereTrailFrom] = useState([])

  const [whereTrailFromOne, setWhereTrailFromOne] = useState([])

  const [whereTrail, setWhereTrail] = useState([])
  const [whereTrailOne, setWhereTrailOne] = useState([])


  useEffect(() => {
    const wherefrom = [...new Set(schedules.map(el => el.wherefrom))]

    const whereSelect = wherefrom.map((el, i) => {
      return {
        name: el,
        value: el
      }
    })
    setWhereTrailFrom(whereSelect)

  }, [schedules])

  function whereSelect({ value }) {
    setWhereTrail([])
    setTimeArr('')
    trailDelete()
    setWhereTrailFromOne(value)

    const where = schedules.filter(el => el.wherefrom === value).map(el => el.where)

    const whereFromSelect = [...new Set(where)].map((el, i) => {
      return {
        name: el,
        value: el
      }
    })
    setWhereTrail(whereFromSelect)

  }

  const [timeArr, setTimeArr] = useState('')
  const [calendarOptions, setCalendarOptions] = useState('')

  const dateConvert = useCallback((d) => new Date(d.split('.')[1] + "-" + d.split('.')[0] + "-" + d.split('.')[2]), [])
  const getShedules = useCallback((date) => {
    const day = daysR[dateConvert(date).getDay()]
    const selectionDate = trails.filter(el => dateConvert(date) >= dateConvert(el.start) && dateConvert(date) <= dateConvert(el.finish))
    const trailID = selectionDate.filter(el => el.day.find(e => e === day)).map(el => el.id)
    const selectionSchedules = schedules.filter(el => trailID.find(e => e === el.trail))
    const currentSchedule = selectionSchedules.filter(el => el.wherefrom === whereTrailFromOne && el.where === whereTrailOne)
    searchCurrentSchedule(currentSchedule, date)
  }, [dateConvert, schedules, searchCurrentSchedule, trails, whereTrailFromOne, whereTrailOne])

  useEffect(() => {
    if (timeArr) {
      setCalendarOptions({
        // inline: true,
        autoClose: true,
        view: "days",
        minView: "months",
        dateFormat: "dd.MM.yyyy",
        multipleDatesSeparator: " - ",
        buttons: ['clear'],
        minDate: new Date(),
        onRenderCell({ date, cellType }) {
          // Disable all 12th dates in month
          if (cellType === 'day') {
            if (timeArr.includes(date.getDate() + "-" + date.getMonth())) {
              return {
                classes: 'active'
              }
            } else {
              return {
                disabled: true
              }
            }
          }
        },
        onSelect({ formattedDate }) {
          getShedules(formattedDate)
        },
        // onHide: (isFinished) => {
        //   isFinished && getShedules()
        // }
      })
    } else {
      setCalendarOptions('')
    }
  }, [timeArr, getShedules])

  function whereFromSelect({ value }) {
    setWhereTrailOne(value)
    setCalendarOptions('')
    const trailParams = schedules.filter(el => el.wherefrom === whereTrailFromOne && el.where === value).map(el => el.trail)
    const timeObj = []

    const timeСonversion = (start, finish, day) => {

      const arrEngDays = []

      const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]

      for (const d of day) {
        const indeX = daysR.findIndex(el => el === d)
        if (indeX !== -1) arrEngDays.push(days[indeX])
      }
      const nowDate = dateConvert(start) >= new Date() ? dateConvert(start) : new Date()
      for (const day = nowDate; day <= dateConvert(finish); day.setDate(day.getDate() + 1)) {
        for (const iterator of arrEngDays) {
          if (iterator === days[day.getDay()]) timeObj.push(day.getDate() + '-' + day.getMonth())
        }
      }

    }

    for (const iterator of trails) {
      for (const iterator2 of trailParams) {
        if (iterator.id === iterator2) timeСonversion(iterator.start, iterator.finish, iterator.day)
      }
    }

    setTimeArr(timeObj);

  }

  return (
    <Row>
      <Col lg="4" md="4">
        <div className="inputStyle">
          <label style={style.label}>Откуда</label>
          <SelectSearch placeholder={"Выберите направление"} options={whereTrailFrom} onChange={whereSelect} />
        </div>
      </Col>
      <Col lg="4" md="4">
        <div className="inputStyle">
          <label style={style.label}>Куда</label>
          <SelectSearch placeholder={"Выберите направление"} options={whereTrail} onChange={whereFromSelect} />
        </div>
      </Col>
      <Col lg="4" md="4">
        {calendarOptions ?
          <Input calendarOptions={calendarOptions} title={'Дата'} className={'calendar'} placeholder={"Выберите дату"} />
          :
          <></>
        }
      </Col>
    </Row>
  )
}

export default SearchSchedule;