import React, { useEffect, useState } from "react";

import SearchSchedule from '../components/SearchSchedule';
import { Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useSelector, useDispatch } from 'react-redux';
import { fetchTrail } from '../store/trailSlice';
import { fetchSchedule } from '../store/scheduleSlice';
import Schedule from '../components/Schedule/Schedule';
import styles from '../components/AddTicketForm/TicketForm.module.sass';

const style = {
  closebutton: {
    top: "15px",
    right: "15px",
    position: "fixed",
  }
}

function Trail() {

  const dispatch = useDispatch()
  const { trails, status, error } = useSelector(state => state.trails)
  const schedules = useSelector(state => state.schedules.schedules)

  useEffect(() => {
    dispatch(fetchTrail())
    dispatch(fetchSchedule())
  }, [dispatch])

  const [currentSchedule, setCurrentSchedule] = useState([])
  const [selectDate, setSelectDate] = useState('')

  function searchCurrentSchedule(params, date) {
    setSelectDate(date)
    setCurrentSchedule(params);
  }
  function trailDelete() {
    setCurrentSchedule([])
  }
  return (
    <section style={style} className="pagesTrailModal">
      <Container>
        <h1>Маршруты</h1>
        <a href="https://gomel-moskva.by/"><span className={styles.closebutton + ' booking-modal-close'} style={style.closebutton}>&times;</span></a>
        <SearchSchedule trailDelete={trailDelete} trails={trails} schedules={schedules} searchCurrentSchedule={searchCurrentSchedule} />
      </Container>
      <Container className="edit-box">
        {status === 'loading' && <h2>Loading...</h2>}
        {error && <h2>{error}</h2>}
        {currentSchedule.map(el =>
          <Schedule date={selectDate} params={el} key={el.id} />
        )}
      </Container>
    </section >
  );
};

export default Trail;