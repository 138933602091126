import React, { useRef, useEffect, useState } from "react";

import { useNavigate, Navigate, Routes, Route } from 'react-router-dom'
import { Container, Button, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import SelectSearch from '../components/UI/SelectSearch/SelectSearch'
import { useSelector } from 'react-redux'

const style = {
  button: {
    marginLeft: '8px'
  },
  loading: {
    position: 'absolute',
    left: "0",
    right: "0",
    width: '100%',
    height: '100%',
    background: "rgba(255,255,255,.9)",
    display: "none",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "28px"
  },
  label: {
    display: "block",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "19px",
    color: "#7B7B7B",
    marginBottom: "5px",
  },
}
function TrailIdPage() {
  const navigate = useNavigate()
  const ticket = useSelector(state => state.tickets.ticket)

  async function sha1(str) {
    const buffer = new TextEncoder("utf-8").encode(str);
    const hash = await crypto.subtle.digest("SHA-1", buffer);
    return Array.from(new Uint8Array(hash)).map(b => b.toString(16).padStart(2, "0")).join("");
  }

  async function main(message) {
    const hash = await sha1(message);
    return String(hash)
  }
  const [kurs, setKurs] = useState('')
  const [card, setCard] = useState('')

  const loading = useRef(null);

  async function pay(params) {
    loading.current.style.display = "flex";
    const random = JSON.stringify(Math.floor(Math.random() * 900000000000000) + 100000000000000);

    const json = {
      "wsb_storeid": "211498828",
      "wsb_order_num": params.id,
      "wsb_currency_id": "BYN",
      "wsb_version": 2,
      "wsb_seed": random,
      "wsb_test": 0,
      "wsb_invoice_item_name": [],
      "wsb_invoice_item_quantity": [],
      "wsb_invoice_item_price": [],
      "wsb_total": 0,
      "wsb_customer_id": params.id,
      "wsb_signature": "",
      // "wsb_3ds_payment_option": "force_3ds",
      "wsb_store": "bron.gomel-moskva.by",
      "wsb_language_id": "russian",
      "wsb_return_url": "http://yoursiteurl.com/success.php",
      "wsb_cancel_return_url": "http://yoursiteurl.com/cancel.php",
      "wsb_notify_url": "http://yoursiteurl.com/notify.php",
      "wsb_customer_name": params.contacts['Имя'],
      // "wsb_customer_address": "Минск ул. Шафарнянская д.11 оф.54",
      // "wsb_service_date": "Доставка до 1 января 2022 года",
      // "wsb_tax": 10.5,
      // "wsb_shipping_name": "Стоимость доставки",
      // "wsb_shipping_price": 0.98,
      // "wsb_discount_name": "Скидка на товар",
      // "wsb_discount_price": 0.58,
      // "wsb_order_tag": "Договор №152/12-1 от 12.01.19",
      "wsb_email": params.contacts['email'],
      "wsb_phone": params.contacts['Код'] + ' ' + params.contacts['Телефон'],
      // "wsb_order_contract": "Договор №152/12-1 от 12.01.19",
      // "wsb_tab": "cardPayment",
      // "wsb_startsesstime": "1603383601",
      // "wsb_startsessdatetime": "2020-10-22T16:20:01+03:00"
    }
    const SecretKey = 'Xh6gO3E0S51';
    let total = 0;

    json["wsb_seed"] = random
    params.tickets.forEach(function (el, i) {
      if (el.hasOwnProperty('price')) {
        let price = parseFloat(el.price.replace(/[^\d.]/g, ''));
        if (card === 'Белорусская карта') {
          console.log(card);
          if (price > 2000) {
            console.log(price, +' ' + kurs);
            json['wsb_invoice_item_price'].push((price * (kurs / 100)).toFixed())
            total += (price * (kurs / 100)).toFixed();
          } else {
            json['wsb_invoice_item_price'].push(price)
            total += price;
          }
        } else {
          json['wsb_invoice_item_price'].push(price)
          total += price;
        }

        json['wsb_invoice_item_name'].push(`Билет-${i + 1}`)
        json['wsb_invoice_item_quantity'].push(1)
      }
    });


    //------------- при выборе российской карты
    if (card === 'Российская карта') {
      if (total > 2000) {
        json['wsb_currency_id'] = "RUB"
      }
      else {
        json['wsb_currency_id'] = "BYN"
      }
    }
    //-------------
    //------------- при выборе белоруской карты
    if (card === 'Белорусская карта') {
      json['wsb_currency_id'] = "BYN"
    }
    json['wsb_total'] = total
    //-------------

    const message = String(random) + String(json["wsb_storeid"]) + String(params.id) + String(params.id) + String(json["wsb_test"]) + String(json["wsb_currency_id"]) + String(json['wsb_total']) + String(SecretKey)
    json["wsb_signature"] = await main(message)
    console.log(json);
    try {
      let response = await fetch('https://bron.gomel-moskva.by/bank-api.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(json),
      })
      let result = await response.json();
      window.location.href = result.data.redirectUrl
    } catch (error) {
      console.log(error.message);
    }
  }


  useEffect(() => {
    async function getKurs() {
      try {
        const response = await fetch('https://api.nbrb.by/exrates/rates/456');
        if (!response.ok) {
          throw new Error(response.statusText);
        }
        const result = await response.json();
        setKurs(result.Cur_OfficialRate)
      } catch (error) {
        alert(error)
        location.href = 'https://bron.gomel-moskva.by/trail'
      }
    } getKurs()
  }, [ticket])

  return (
    <section className="pages">
      <Container>
        <div style={style.loading} ref={loading} >Секундочку.....</div>
        {ticket ?
          <>
            <h1>{ticket.messages?.success}</h1>
            <h4></h4>
            <p>Бронь № <b>{ticket.id}</b></p>
            <p>Дата: <b>{ticket.messages?.trailDate}</b></p>
            <p><b>{ticket.messages?.trailFrom}</b></p>
            <p><b>{ticket.messages?.trailTo}</b></p>
            <p>Время в пути: <b>{ticket.messages?.timeWay}</b></p>
            <p>Стоимость итого: <b>{ticket.messages?.price} руб.</b></p>
            <p>! На почту которую вы указали при бронировании выслан электронный билет. Если вы не видите письмо проверьте спам. Или позвоните по любому номеру представленному на сайте.</p>
            <Row>
              <Col lg="2">
                <div className="inputStyle">
                  <label style={style.label}>Вернуться назад</label>
                  <Button onClick={() => navigate(-1)}>←</Button>
                </div>
              </Col>
              <Col lg="3">
                {!card && <div className="inputStyle">
                  <label style={style.label}>Какой картой будете производить оплату</label>
                  <SelectSearch placeholder="Выбрать..." options={[{ value: 'Российская карта', name: 'card' }, { value: 'Белорусская карта', name: 'card' }]} defaultValue={[{ value: '', name: 'card' }]} onChange={(e) => setCard(e.value)} />
                </div>}
                {card && <div className="inputStyle"><label style={style.label}>Оплатить</label>
                  <Button style={style.button} onClick={() => pay(ticket)} className="btn btn-danger" variant="danger">Оплатить</Button>
                </div>}
              </Col>
            </Row>
          </>
          :
          <Routes>
            <Route path='*' element={<Navigate to="/trail" replace />} />
          </Routes>
        }
      </Container>
    </section >
  );
};

export default TrailIdPage;