import React from 'react';
import { Col, } from 'react-bootstrap';
const styles = {

  label: {
    display: "block",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "19px",
    color: "#7B7B7B",
    marginBottom: "5px",
  },
  input: {
    maxWidth: "270px",
    width: "100%",
    height: "40px",
    padding: "0 8px",
    border: "1px solid #DEDEDE",
    boxSizing: "border-box",
    borderRadius: "3px",
  }
}
function ImportSchedule({ title, className, value }) {
  return (
    <Col lg="3" md="3" className={className}>
      <div className="inputStyle">
        <label style={styles.label}>{title}</label>
        <input style={styles.input} type="text" name={value} readOnly value={value} />
      </div>
    </Col>
  );
}

export default ImportSchedule;