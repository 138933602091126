import React, { useEffect, useState } from 'react';
import Input from '../UI/Input';
import InputMask from 'react-input-mask';
import SelectSearch from '../UI/SelectSearch/SelectSearch'
import { Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import styles from './TicketForm.module.sass';
import { fetchOptions } from '../../store/optionsSlice'
import { useDispatch, useSelector } from 'react-redux';
const inputPhoneStyle = {
  inputPhoneBox: {
    display: 'flex',
    alignItems: 'center'
  },
  p: {
    margin: '0 5px 0 0'
  },
  inputPhone: {
    marginLeft: '8px',
    width: "190px",
    height: "40px",
    padding: "0 8px",
    border: "1px solid #DEDEDE",
    boxSizing: "border-box",
    borderRadius: "3px",
  },
  label: {
    display: "block",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "19px",
    color: "#7B7B7B",
    marginBottom: "5px",
  },
  phoneCode: {
    width: 'auto',
  },
}

const calendarOptions = {
  autoClose: true,
  view: "days",
  // minView: "months",
  dateFormat: "dd.MM.yyyy",
  multipleDatesSeparator: " - ",
  buttons: ['clear'],
}

function TicketForm({ idx, selectedSchedule, idTicket, removeTicket, childrenWatch }) {
  const { childrensales } = useSelector(state => state.options.options)
  const dispatch = useDispatch()
  const [val, setVal] = useState('+375');
  const [price, setPrice] = useState('');
  useEffect(() => {
    dispatch(fetchOptions())
  }, [dispatch])
  useEffect(() => {
    if (selectedSchedule?.price) {
      setPrice(selectedSchedule.price.replace(/[A-z]|[А-я]/g, ''))
    }
  }, [selectedSchedule])

  function getSelect({ value }) {
    setVal(value)
  }
  const [newPrice, setNewPrice] = useState(price);

  useEffect(() => {
    setNewPrice(price);
    childrenWatch(Math.floor(10000000 + Math.random() * 90000000))
  }, [price, childrenWatch])

  function childrenSales(params) {
    if (params.checked) {
      const cost = Number(price) - Number(price) * Number(childrensales) / 100
      setNewPrice(cost);
    } else {
      setNewPrice(price);
    }
    childrenWatch(Math.floor(10000000 + Math.random() * 90000000))
  }
  return (
    <Row className={styles.AddTicket + ' add-booking ticket-form'} id={'ticket-form-' + idx}>
      <div>
        <div className={styles.tickettitle}>
          <span className={styles.closebutton} onClick={() => removeTicket(idTicket)}>&times;</span>
          <p>Билет: {idTicket + 1}</p>
        </div>
        <label className={styles.inputCheck} htmlFor={"children-" + idx}>
          <span>Детский</span>
          <input id={"children-" + idx} type="checkbox" name={'Возраст'} value="Детский" onChange={(e) => childrenSales(e.target)} />
        </label>
      </div>
      <Col lg="2" className='mb-3 mt-3'>
        <Input title="Имя" name={'Имя'} />
      </Col>
      <Col lg="2" className='mb-3 mt-3'>
        <Input title="Отчество" name={'Отчество'} />
      </Col>
      <Col lg="2" className='mb-3 mt-3'>
        <Input title="Фамилия" name={'Фамилия'} />
      </Col>
      <Col lg="3" className='mb-3 mt-3'>
        <div className="inputStyle">
          <label style={inputPhoneStyle.label}>Тип документа</label>
          <SelectSearch placeholder="Выбрать..." options={[{ value: 'Паспорт', name: 'Тип_документа' }, { value: 'Вид на жительство', name: 'Тип_документа' }, { value: 'Другой документ', name: 'Тип_документа' }]} />
        </div>
      </Col>
      <Col lg="2" className='mb-3 mt-3'>
        <Input title="Номер документа" name={'Номер_документа'} />
      </Col>
      <Col lg="2" className='mb-3 mt-3'>
        <Input calendarOptions={calendarOptions} className={'calendar' + idx} title="Дата рождения" name={'Дата_рождения'} />
      </Col>
      <Col lg="2" className='mb-3 mt-3'>
        <div className="inputStyle inputSelect">
          <label style={inputPhoneStyle.label}>Пол</label>
          <SelectSearch placeholder="Выбрать..." options={[{ value: 'Мужской', name: 'Пол' }, { value: 'Женский', name: 'Пол' }]} />
        </div>
      </Col>
      <Col lg="2" className='mb-3 mt-3'>
        <Input title="Гражданство" name={'Гражданство'} />
      </Col>
      <Col lg="3" className='mb-3 mt-3'>
        <div className="inputStyle">
          <label style={inputPhoneStyle.label}>Телефон</label>
          <div style={inputPhoneStyle.inputPhoneBox} className="input-phone-box">
            <p style={inputPhoneStyle.p}>+</p>
            <SelectSearch defaultValue={{ name: 'Код', value: val }} onChange={getSelect}
              options={[{ value: '+375', name: 'Код' }, { value: '+7', name: 'Код' }]}
            />
            {val === '+375'
              ? <InputMask name={'Телефон'} style={inputPhoneStyle.inputPhone} mask="(99) 999 99 99" maskChar="_" placeholder="(__) ___ __ __" key="1" />
              : <InputMask name={'Телефон'} style={inputPhoneStyle.inputPhone} mask="(999) 999 99 99" maskChar="_" placeholder="(___) ___ __ __" key="2" />
            }

          </div>
        </div>
      </Col>
      <Col lg="3" className={'mb-3 mt-3 ' + styles.pricebox} >
        <div className={styles.price}>
          <input name="price" readOnly className={styles.pricefield} value={`Цена: ${newPrice} РУБ`} />
        </div>
      </Col>
      <Col lg="6" className='mb-3 mt-0 but-box'>
      </Col>
    </Row>
  );
}

export default TicketForm 