import React, { useRef, useState } from 'react';

import Input from '../UI/Input';
import InputMask from 'react-input-mask';
import SelectSearch from '../UI/SelectSearch/SelectSearch'
import { Button, Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate, Navigate, Routes, Route } from 'react-router-dom'
import { creatTicket } from '../../store/ticketSlice'
import { useDispatch } from 'react-redux';
import { alertErr } from '../../components/UI/alert'

const style = {
  h3: {
    marginTop: '15px'
  },
  button: {
    width: '100%'
  },
  label: {
    display: "block",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "19px",
    color: "#7B7B7B",
    marginBottom: "5px",
  },
}

const inputPhoneStyle = {
  inputPhoneBox: {
    display: 'flex',
    alignItems: 'center'
  },
  p: {
    margin: '0 5px 0 0'
  },
  inputPhone: {
    marginLeft: '8px',
    width: "190px",
    height: "40px",
    padding: "0 8px",
    border: "1px solid #DEDEDE",
    boxSizing: "border-box",
    borderRadius: "3px",
  }
}

function ContactsForm({ selectedSchedule, ticketsPriceSum, selectDate }) {

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const ticketContactForm = useRef('')

  function sendTickets() {
    const error = new Set()
    const ticketForm = document.querySelectorAll('.ticket-form')
    const arr = [...ticketForm].map(elm => elm.id);
    const tickets = {
      id: (new Date().getTime() / 1000).toFixed(),
      contacts: {},
      tickets: [],
      messages: ""
    }

    arr.forEach(id => {
      const el = document.querySelectorAll(`#${id} input`)
      const ar = {
        [id]: new Map()
      }
      el.forEach(e => {
        if (!e.value) error.add('err')
        if (e.checked) ar[id].set(e.name, e.value)
        if (e.type !== 'checkbox') ar[id].set(e.name, e.value)
      })
      tickets.tickets.push(Object.fromEntries(ar[id]))

    })
    const contacts = ticketContactForm.current.querySelectorAll('input')
    contacts.forEach(contact => {
      if (!contact.value) error.add('err')
      else tickets.contacts[contact.name] = contact.value

    })
    let idx = ''
    const schedule = [selectedSchedule].map(name => {
      idx = name.id
      // 'Гомель отп.04:46 Автовокзал2  - Москва приб.07:46 Универмаг "Гомель" '
      return {
        success: "Успешно забронировано!",
        trailFrom: "Отправление: " + name.wherefrom + " " + name.stoppingsending + " в " + name.sendingtime,
        trailTo: "Прибытие: " + name.where + " " + name.arrivalstop + " в " + name.arrivaltime,
        trailDate: selectDate,
        timeWay: name.traveltime,
        price: ticketsPriceSum,
      }
    })
    tickets.messages = schedule[0]
    if (error.size > 0) {
      alertErr.fire({
        text: 'Нужно заполнить все поля!',
      })
      return
    }
    dispatch(creatTicket({ tickets, idx }))
    setTimeout(() => {
      navigate("/success-add-ticket");
    }, 1000)

  }

  const [val, setVal] = useState('+375');

  function getSelect({ value }) {
    setVal(value)
  }
  return (
    <>
      {selectedSchedule ?
        <Container>
          <h3 style={style.h3}>Контактные данные</h3>
          <p>Контактные данные нужены для отправки данных о бронировании, электронных билетов и срочных оповещений в случае любых изменений</p>
          <Row className='align-items-end' ref={ticketContactForm}>
            <Col lg="3" className='mb-3 mt-3'>
              <Input title="Имя" name='Имя' />
            </Col>
            <Col lg="3" className='mb-3 mt-3'>
              <div className="inputStyle">
                <label style={style.label}>Телефон</label>
                <div style={inputPhoneStyle.inputPhoneBox} className="input-phone-box">
                  <p style={inputPhoneStyle.p}>+</p>
                  <SelectSearch defaultValue={{ name: 'Код', value: '+375' }} onChange={getSelect}
                    options={[{ value: '+375', name: 'Код' }, { value: '+7', name: 'Код' }]}
                  />
                  {val === '+375'
                    ? <InputMask name='Телефон' style={inputPhoneStyle.inputPhone} mask="(99) 999 99 99" maskChar="_" placeholder="(__) ___ __ __" key="1" />
                    : <InputMask name='Телефон' style={inputPhoneStyle.inputPhone} mask="(999) 999 99 99" maskChar="_" placeholder="(___) ___ __ __" key="2" />
                  }
                </div>
              </div>
            </Col>
            <Col lg="3" className='mb-3 mt-3'>
              <Input title="Email" type="email" name='email' />
            </Col>
            <Col lg="3" className='mb-3 mt-4'>
              <Button style={style.button} onClick={() => sendTickets()}>Сохранить</Button>
            </Col>
          </Row>
          <p>Нажимая кнопку "Бронировать" или "Оплатить online" Вы подтверждание правильность введенных данных и принимаете условия Публичной оферты и Правила покупки и возврата билетов</p>
        </Container>

        :
        <Routes>
          <Route path='*' element={<Navigate to="/add-booking" replace />} />
        </Routes>
      }
    </>
  );
}

export default React.memo(ContactsForm);