import React from 'react';
import { createRoot } from 'react-dom/client';

import { BrowserRouter, Routes, Route } from 'react-router-dom'

import { Provider } from 'react-redux';
import store from './store'
import App from './App';

import reportWebVitals from './reportWebVitals';

const div = document.createElement('div')
div.id = 'root'
const body = document.querySelector('body')
body.append(div)
const root = createRoot(document.getElementById('root'))

root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <BrowserRouter>
      <Routes>
        <Route path="/*" element={<App />}></Route>
      </Routes>
    </BrowserRouter>
  </Provider>
  // </React.StrictMode>
)

reportWebVitals();
