import { configureStore } from "@reduxjs/toolkit";
import trailReducer from "./trailSlice";
import scheduleReducer from "./scheduleSlice";
import ticketReducer from "./ticketSlice";
import optionsReducer from "./optionsSlice";
export default configureStore({
  reducer: {
    trails: trailReducer,
    schedules: scheduleReducer,
    tickets: ticketReducer,
    options: optionsReducer,
  }
})