import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { alertErr, success } from '../components/UI/alert'

export const fetchSchedule = createAsyncThunk(
  'schedule/fetchSchedule',
  async function (idx, { rejectWithValue }) {
    try {
      const response = await fetch(`https://booking.gomel-moskva.by/core/schedule/${idx}`, {
        method: 'GET',
      })
      if (!response.ok) throw new Error('Server Error!');
      const data = await response.json()
      const arr = []
      for (const key in data) {
        const newObject = {
          trail: data[key].trail,
          id: data[key].id,
          ...JSON.parse(data[key].schedule)
        }
        arr.push(newObject)
      }

      return arr

    } catch (error) {
      alertErr.fire({
        text: 'Ошибка сервера! Не удалось получить данные.',
      })
      return rejectWithValue(error.message)
    }
  }
)

const setError = (state, action) => {
  state.status = 'rejected'
  state.error = action.payload
}

const scheduleSlice = createSlice({
  name: 'schedule',
  initialState: {
    schedules: [],
    status: null,
    error: null,
  },
  reducers: {},
  extraReducers: {
    [fetchSchedule.pending]: (state) => {
      state.status = 'loading'
      state.error = null
    },
    [fetchSchedule.fulfilled]: (state, action) => {
      state.status = 'resolved'
      state.schedules = action.payload
    },
    [fetchSchedule.rejected]: setError,
  }
})
export default scheduleSlice.reducer