import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { alertErr, success } from '../components/UI/alert'

export const creatTicket = createAsyncThunk(
  'ticket/creatTicket',
  async function ({ tickets, idx }, { rejectWithValue, dispatch }) {
    try {
      const response = await fetch(`https://booking.gomel-moskva.by/core/ticket`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 'ticket': tickets, 'id': idx })
      })
      if (!response.ok) throw new Error('Can\'t create task. Server Error!');
      const data = await response.json()

      dispatch(addTicket(JSON.parse(data.data.ticket)))

    } catch (error) {
      alertErr.fire({
        text: 'Ошибка сервера! Объект не удалось создать.',
      })
      return rejectWithValue(error.message)
    }
  }
)

const setError = (state, action) => {
  state.status = 'rejected'
  state.error = action.payload
}
const ticketSlice = createSlice({
  name: 'tickets',
  initialState: {
    tickets: [],
    ticket: '',
    status: null,
    error: null,
  },
  reducers: {
    addTicket(state, action) {
      state.ticket = action.payload
      const email = action.payload.contacts.email
      const title = `Бронь билетов в компании "Столица". Телефоны: +375 29 660-99-88 Гомель | +375 33 660-99-88 Гомель | +7 925 311-92-35 Москва)`
      const booking = `№ ${action.payload.id}`
      const date = `${action.payload.messages.trailDate}`
      const trailFrom = `${action.payload.messages.trailFrom}`
      const trailTo = `${action.payload.messages.trailTo}`
      const time = `${action.payload.messages.timeWay}`
      const price = `${action.payload.messages.price} руб.`
      const formData = new FormData();
      formData.append('email', email)
      formData.append('title', title)
      formData.append('Бронь', booking)
      formData.append('Дата', date)
      formData.append('Отпр', trailFrom)
      formData.append('Приб', trailTo)
      formData.append('Время_в_пути', time)
      formData.append('Стоимость_и_того', price)

      fetch('/mail.php', {
        method: 'POST',
        body: formData
      }).then( (response) => {
          if( !response.ok ) throw new Error('mail.php error');
          console.log("ok")
      }).catch( (error) =>{
          console.log(error)
      })
    },
  },
  extraReducers: {
    [creatTicket.pending]: (state) => {
      state.status = null
      state.error = null
    },
    [creatTicket.rejected]: setError,
  }
})
export const { addTicket} = ticketSlice.actions
export default ticketSlice.reducer